import { FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Title } from "components/text";
import { Button, ButtonGroup } from "components/buttons";
import { TextFieldInput } from "components/input";
import { Link } from "components/link";
import { Text } from "components/text";
import UserContext from "./UserContext";
import styles from "./UserAuthDialog.module.css";
import { AuthNextSignInStep } from "@aws-amplify/auth/dist/esm/types";

/** The props used for the {@link UserSignInDialog} component. */
interface UserSignInDialogProps {
  /** Whether to show the title of the sign in dialog. */
  showTitle?: boolean;
  /** Event handler for setting the Cognito User. */
  onChangeCognitoUser?: (user: AuthNextSignInStep) => void;  
}

/** A component that renders a user sign-in dialog. */
const UserSignInDialog: FC<UserSignInDialogProps> = ({
  showTitle,
  onChangeCognitoUser = () => {},
}) => {
  // We use the username and password to call signin endpoints.
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // Set an error message if applicable for when the form is submitted
  const [apiError, setApiError] = useState<string | null>(null);

  // We rely upon the user context to handle sign in
  const { signIn } = useContext(UserContext);

  // We use history to go back to the home page when sign-in is successful
  const history = useHistory();

  // The user name may be set in a query parameter
  const location = useLocation();
  useEffect(() => {
    const queryUsername = new URLSearchParams(location.search).get("username");
    setUsername(queryUsername ?? "");
  }, [location]);

  // This handles when the user presses the sign in button.
  const handleSignIn = async (event: React.FormEvent) => {
    event.preventDefault();
    if (password === "") {
      setApiError("Password is required.");
      return;
    }
    try {
      const cognitoUserResponse = (await signIn(
        username,
        password
      ));
      setApiError(null);
      if (cognitoUserResponse === null) {
        history.push("/");
      } else {
        // Save the Congito response in preparation for validating the password
        onChangeCognitoUser(cognitoUserResponse);
      }
    } catch (error: any) {
      setApiError(error.message);
    }
  };

  return (
    <>
      <form
        className={styles.dialog}
        onSubmit={(event) => {
          handleSignIn(event);
        }}
      >
        {showTitle && <Title>Sign In</Title>}
        <label className={styles.dialogField}>
          <span>Username</span>
          <TextFieldInput
            value={username}
            onChange={setUsername}
            placeholder="Username"
          />
        </label>
        <label className={styles.dialogField}>
          <span>Password</span>
          <TextFieldInput
            value={password}
            onChange={setPassword}
            placeholder="Password"
            password
          />
        </label>
        <Text padding="bottom" justify="center">
          <Link to="/signin/forgotpassword">Forgot Password?</Link>
        </Text>
        <ButtonGroup stretch>
          <Button type="submit" sizing="bulky" className={styles.dialogButton}>
            Sign In
          </Button>
        </ButtonGroup>
        {apiError && (
          <Text color="error" size="small" padding="center">
            {apiError}
          </Text>
        )}
      </form>
    </>
  );
};

export default UserSignInDialog;
export type { UserSignInDialogProps };
