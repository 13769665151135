
import { JSONSchema7, JSONSchema7Definition } from "json-schema";
import { KeyInfo } from "data/types";
import { RegistryFieldsType  } from "@rjsf/utils";
import { PrimaryKeyField } from "./PrimaryKeyField";
import { ForeignKeyField } from "./ForeignKeyField";
import NumberField from "./NumberField";

function exploreSchema(schema: JSONSchema7, path: string[]){
    const v = path.reduce((current: JSONSchema7 | JSONSchema7Definition | null, pathElement)=>{
      if(!current){
        return null;
      }
  
      if(typeof current === 'boolean'){
        return null;
      }
  
      if(!current.properties){
        return null;
      }
  
      return current.properties[pathElement]
  
    }, schema)
  
    if(typeof v === 'boolean')
      return null;
  
    return v;
  }

  
  export const CartaWidgetFields: RegistryFieldsType ={
    'pk': PrimaryKeyField,
    'fk': ForeignKeyField,
    "NumberField": NumberField
  }

  
  export function assignKeyIds(schema: JSONSchema7, keyInfo: KeyInfo){
    if(keyInfo.primaryKeyPath){
    
      const sampleId = exploreSchema(schema, keyInfo.primaryKeyPath);
      if(sampleId){
        sampleId.$id = 'pk';
      }
    }
  
    if(keyInfo.foreignKeyPaths){
      keyInfo.foreignKeyPaths.forEach(fk=>{
        const foreignKey = exploreSchema(schema, fk);
        if(foreignKey){
          foreignKey.$id = 'fk';
        }
      })
    }
  }