import { FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Title } from "components/text";
import { Button, ButtonGroup } from "components/buttons";
import { TextFieldInput } from "components/input";
import { Text } from "components/text";
import { Tooltip } from "components/tooltip";
import UserContext from "./UserContext";
import UserValidatedPasswordInput from "./UserValidatedPasswordInput";
import styles from "./UserAuthDialog.module.css";

/** The props used for the {@link UserSignInDialog} component. */
interface UserSignInCompleteDialogProps {
  /** Whether to show the title of the sign in dialog. */
  showTitle?: boolean;
}

/** A component that renders a user sign-in dialog. */
const UserSignInCompleteDialog: FC<UserSignInCompleteDialogProps> = ({
  showTitle
}) => {
  // At first sign-in, first and last name needs to be completed.
  // We also need to verify that the password was correctly re-typed
  // and conforms to the password policy
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCopy, setPasswordCopy] = useState("");

  // First and last name errors
  const [firstnameError, setfirstnameError] = useState<string | null>(null);
  const [lastnameError, setlastnameError] = useState<string | null>(null);

  // Set an error message when the form is submitted and an API error occurs
  const [apiError, setApiError] = useState<string | null>(null);

  // We rely upon the user context to handle sign in completion/
  const { completeSignIn } = useContext(UserContext);

  // We use history to go back to the home page when sign-in is successful
  const history = useHistory();

  // This handles when the user presses the sign in button.
  const handleSignInComplete = async (event: React.FormEvent) => {
    event.preventDefault();
    if (firstname === "") {
      setfirstnameError("First name is required.");
    }
    if (lastname === "") {
      setlastnameError("Last name is required.");
    }
    if (firstname === "" || lastname === "") {
      return;
    }
    try {
      await completeSignIn(password, firstname, lastname);
      setApiError(null);
      history.push("/");
    } catch (error: any) {
      setApiError(error.message);
    }
  };

  // This handles when the user has entered a first name
  const handleFirstname = () => {
    setfirstnameError(null);
  };

  // This handles when the user has entered a lastname
  const handleLastname = () => {
    setlastnameError(null);
  };

  return (
    <form
      className={styles.dialog}
      onSubmit={(event) => {
        handleSignInComplete(event);
      }}
    >
      {showTitle && <Title>Complete Sign In</Title>}
      <label className={styles.dialogField}>
        <span>First name</span>
        <Tooltip
          component={
            <Text color="error" key="firstnameError">
              {firstnameError}
            </Text>
          }
          hide={firstnameError === null}
          options={{ placement: "right" }}
        >
          <TextFieldInput
            value={firstname}
            onChange={setFirstname}
            onBlur={handleFirstname}
            placeholder="First Name"
          />
        </Tooltip>
      </label>
      <label className={styles.dialogField}>
        <span>Last name</span>
        <Tooltip
          component={
            <Text color="error" key="lastnameError">
              {lastnameError}
            </Text>
          }
          hide={lastnameError === null}
          options={{ placement: "right" }}
        >
          <TextFieldInput
            value={lastname}
            onChange={setLastname}
            onBlur={handleLastname}
            placeholder="Last Name"
          />
        </Tooltip>
      </label>
      <UserValidatedPasswordInput
        password={password}
        passwordCopy={passwordCopy}
        onPasswordChanged={setPassword}
        onPasswordCopyChanged={setPasswordCopy}
      />
      <ButtonGroup stretch>
        <Button type="submit" sizing="bulky" className={styles.dialogButton}>
          Sign In
        </Button>
      </ButtonGroup>
      {apiError && (
        <Text color="error" size="small" padding="center">
          {apiError}
        </Text>
      )}
    </form>
  );
};

export default UserSignInCompleteDialog;
export type { UserSignInCompleteDialogProps };
