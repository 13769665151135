import { FunctionComponent, useState, useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "components/user";
import { PageLayout, Wrapper } from "components/layout";
import { Text } from "components/text";
import { UserNeedsAuthentication } from "components/user";
import useCartaObjectStorage from "hooks/useCartaObjectStorage";
import { NavigationLink } from "components/navigation";
import { IconButton, Alert } from "@mui/material";
//import QrCodeIcon from "components/icons/QrCodeIcon";
import QRCode from "react-qr-code";
import { QrCode as QrCodeIcon } from "@mui/icons-material";
import { CollectionForm as CollectionFormComponent } from "components/collectionsForm";
import { IFormSchemaResponse, NativeId } from "data/types";





const CollectionForm: FunctionComponent = () => {
  const [error, setError] = useState<string>();
  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");
  const [connectionId, setConnectionId] = useState<string | undefined>(undefined);
  const [nativeId, setNativeId] = useState<NativeId | null>(null);
  const [schema, setSchema] = useState<IFormSchemaResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const qrcode = useRef<HTMLDivElement>(null);
  const cartaObjectStorage = useCartaObjectStorage();
  

  const openQrCode = () => {
    const qrWindow = window.open("", "", "width=600,height=400,top=0,left=0");
    if (!qrWindow) {
      return;
    }
    qrWindow.document.write(qrcode.current?.innerHTML ?? "");
    qrWindow.document.close();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _search = new URLSearchParams(location.search);
        const projectId = _search.get("projectId");
        const key = _search.get("key");
        const connectionId = _search.get("connectionId");
        const nativeId = _search.get("nativeId");
        const parentNativeId = _search.get("parentNativeId");

        let collectionProps;

        if (projectId) {
          setProjectId(projectId);
          if (key) {
            collectionProps = { projectId: projectId, key: key };
          } else if (connectionId && nativeId) {
            setNativeId(JSON.parse(nativeId));
            setConnectionId(connectionId);
            collectionProps = {
              projectId: projectId,
              connectionId: connectionId,
              node: { nativeId: JSON.parse(nativeId) },
              parentNode: {
                nativeId: parentNativeId ? JSON.parse(parentNativeId) : {},
              },
            };
          }
        }

        if (!collectionProps) {
          throw new Error("Not enough data to construct collection request.");
        }

        const _cartaObjectStorage = await cartaObjectStorage;
        const getProjectName = _cartaObjectStorage
          .listProjects()
          .then((projects) => projects.find((_project) => _project.projectId === projectId))
          .then((project) => setProjectName(project?.name ?? ""));

        const getResourceData = _cartaObjectStorage.getFormSchema(collectionProps).then((schema) => {
          setSchema(schema);
        });

        await Promise.all([getProjectName, getResourceData]);
        setLoading(false);
      } catch (error) {
        setError(error instanceof Error ? error.message : "An unknown error occurred.");
        setLoading(false);
      }
    };

    fetchData();
  }, [location, cartaObjectStorage]);

  if (loading) {
    return (
      <Text size="medium" padding="center">
        Loading form schema...
      </Text>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        {error}
      </Alert>
    );
  } else {
    return (
      <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", width: "100%" }}>
        <h2>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <NavigationLink to={`/?projectId=${projectId}`}>
              {`${projectName}: ${schema?.schema?.title || ""}`}
            </NavigationLink>
            {nativeId ? null : (
              <>
                <div style={{ display: "none" }} ref={qrcode}>
                  <h2>{`${projectName}: ${schema?.schema?.title || ""}`}</h2>
                  <QRCode size={256} value={document.location.toString()} />
                </div>
                <IconButton
                  onClick={openQrCode}
                  style={{ backgroundColor: "#777", color: "white", marginLeft: "1rem" }}>
                  <QrCodeIcon />
                </IconButton>
              </>
            )}
          </div>
        </h2>
        <CollectionFormComponent projectId={projectId} connectionId={connectionId} schema={schema}/>
        
      </div>
    );
  }
};

const CollectionFormPage: FunctionComponent = () => {
  const { authenticated } = useContext(UserContext);

  return (
    <PageLayout header footer>
      <Wrapper>{authenticated ? <CollectionForm /> : <UserNeedsAuthentication />}</Wrapper>
    </PageLayout>
  );
};

export default CollectionFormPage;

