import { FunctionComponent, useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { JSONSchema7 } from "json-schema";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { TableFormField } from "./fields";
import { IMonitorForm } from "data/formStatus";
import { FormStatusIcon } from "../collectionLongForm/components/FormStatusIcon";

import "./CollectionTableForm.css"; // Import the CSS file
import { ISchemaProperty } from "data/types";
import { flattenDependencies } from "data/formManipulation";

type OnChangeCallback = (index: number, data: any, hasError: boolean) => void;
type IndexCallback = (index: number) => void;

interface CollectionTableFormProps {
  forms: IMonitorForm[];
  schema: any;
  onChange: OnChangeCallback;
  onDuplicate: IndexCallback;
  onRemove: IndexCallback;
}

interface HeaderLayer{
  createHeaderCell(index: number): JSX.Element;
}

class BufferLayer implements HeaderLayer{
  constructor(public span: number){

  }

  createHeaderCell(index: number) {
    return (<TableCell key={index} colSpan={this.span}/>)
  }
}

class Layer implements HeaderLayer{

  title: string;
  children: { [key: string]: Layer } = {};
  sortedChildren: Layer[] = [];
  isLeaf: boolean;

  leaves: Layer[] = [];

  constructor(public property: JSONSchema7, public path: string[] = []) {
    property = {...property}
    this.title = property.title ?? "";

    if (!property.properties) {
      this.isLeaf = true;
      return; 
    }

    flattenDependencies(property);

    this.children = (Object.entries(property.properties)
      .filter(([_, property]) => (typeof property !== 'boolean')) as [string, JSONSchema7][])
      .reduce((children, [key, property]) => {
        children[key] = new Layer(property, [...path, key])
        return children;
      }, {} as { [key: string]: Layer });

      this.sortedChildren = Object.values(this.children).sort(Layer.sortProperties);

    this.isLeaf = false;

    if(this.isRoot){
      this.leaves = this.getLeaves();
    }    
  }

  get isRoot(){
    return this.path.length === 0;
  }

  getHeaders(layers?: HeaderLayer[][], currentDepth: number = 0, maxDepth: number = 0){
    if(typeof layers === 'undefined'){
      maxDepth = this.depth - 1;
      layers = [...new Array(maxDepth)].map(()=>[]);
      currentDepth = 0;
    }

    if(this.isLeaf){
      for(let i = currentDepth; i < maxDepth; i++){
        layers[i].push(new BufferLayer(1));
      }
      return;
    }

    layers[currentDepth].push(this);
    this.sortedChildren.forEach((child)=>{
      child.getHeaders(layers, currentDepth + 1, maxDepth);
    });
  }

  getLeaves(): Layer[]{
    if(this.leaves.length === 0){

      if(this.isLeaf){
        return [this]
      }

      this.leaves = this.sortedChildren.flatMap(child=>child.getLeaves());
    }
    return this.leaves;
  }

  get depth(): number{
    return 1 + Math.max(0, ...Object.values(this.children).map(child=>child.depth))
  }

  get span(): number {
    const childrenList = Object.values(this.children);
    if (childrenList.length > 0) {
      return childrenList.reduce((sum, child) => (child.span + sum), 0);
    }

    return 1;
  }

  createHeaderCell(index: number) {
    return (<TableCell key={index} colSpan={this.span}>{this.title}</TableCell>)
  }

  createBufferHeaderCell(index: number){
    return (<TableCell key={index} colSpan={this.span}/>)
  }

  createTableHeaderRow() {
    return (<>
      {Object.values(this.children).sort(Layer.sortProperties).map((child, index) => child.createHeaderCell(index))}
      </>)
  }

  setFormValue(form: any, value: any){
    const endpoint = this.path.slice(0, -1).reduce((current, pathElement)=>{
      if(!current[pathElement]){
        current[pathElement] = {};
      }
      return current[pathElement];
    }, form);

    const endPathElement = this.path.at(-1);
    if(!endPathElement){
      throw new Error("Path doesn't make sense");
    }
    endpoint[endPathElement] = value;
  }

  getFormValue(form: any){
    return this.path.reduce((current, pathElement)=>{
      if(!current){
        return undefined;
      }

      return current[pathElement];
    }, form);
  }

  createTableDataCell(index: number, form: IMonitorForm, onChange: (changeValue: any, hasError: boolean)=>void) {
    let fieldValue: any = "";
    if (form.data) {
      fieldValue = this.getFormValue(form.data);
    } else {
      fieldValue = this.property.default ?? "";
    }
    
    return (<TableCell key={index}>
      <TableFormField
        onChange={(value) => {
          if(!form.data){
            form.data = {};
          }
          const newValue = JSON.parse(JSON.stringify(form.data))
         
          this.setFormValue(newValue, value);
          onChange(newValue, false);
         }}
        property={this.property}
        defaultValue={fieldValue}
      />
    </TableCell>)
  }

  private static sortProperties(a: Layer, b: Layer){
      const aOrder = (a.property as ISchemaProperty).propertyOrder ?? Number.MAX_SAFE_INTEGER;
      const bOrder = (b.property as ISchemaProperty).propertyOrder ?? Number.MAX_SAFE_INTEGER;
      return aOrder - bOrder;
  }

  createTableLeafHeaderRow() {
    return (
      <TableRow>
            <TableCell />
        {this.getLeaves().map((layer, index) => layer.createHeaderCell(index))}
        <TableCell />
      </TableRow>
      )
  }

  createTableHeader() {

    const depth = this.depth - 1;
    const rows: HeaderLayer[][] = [...new Array(depth)].map(() => []);
    this.getHeaders(rows, 0, depth);


    return (<TableHead>
      {rows.slice(1).map((row, index) => {
        return (
          <TableRow key={index}>
            <TableCell />

            {row.map((layer, index) => layer.createHeaderCell(index))}

            <TableCell />
          </TableRow>)
      })}
      {this.createTableLeafHeaderRow()}

    </TableHead>)
  }

  createDataRow(index: number, form: IMonitorForm, onChange: OnChangeCallback, onDuplicate: IndexCallback, onDelete: IndexCallback) {
    
    return (<TableRow key={index}>
      
      <TableCell>
        <FormStatusIcon status={form.status} isError={form.isError}/>
      </TableCell>

      {this.getLeaves().map((leaf, propIndex)=>leaf.createTableDataCell(propIndex, form, (changedValue, hasError)=>onChange(index, changedValue, hasError)))}

      <TableCell>
        <IconButton aria-label="duplicate form" onClick={() => onDuplicate(index)} color="primary">
          <ContentCopyIcon />
        </IconButton>
        <IconButton aria-label="remove form" onClick={() => onDelete(index)} color="secondary">
          <DeleteIcon />
        </IconButton>
      </TableCell>
      </TableRow>)
  }

}


export const CollectionTableForm: FunctionComponent<CollectionTableFormProps> = ({
  forms,
  schema,
  onChange,
  onDuplicate,
  onRemove,
}) => {

  const [layerProperties, setLayerProperties] = useState<Layer | null>(null)

  useEffect(()=>{
    setLayerProperties(new Layer(schema));
  }, [schema])

  if(!layerProperties){
    return (<>Loading schema...</>)
  }

  return (
    <div>
      <div className="controls">{/* Add your + button, QR code, or Save Forms UI here */}</div>
      <div className="table-container">
        <TableContainer component={Paper}>
          <Table stickyHeader>
            { layerProperties.createTableHeader() }
            <TableBody>
              {forms.map((form, index) => {
                return layerProperties.createDataRow(index, form, onChange, onDuplicate, onRemove);
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CollectionTableForm;
