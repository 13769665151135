import { FunctionComponent, useState } from "react";
import { PageLayout } from "components/layout";
import { UserSignInDialog, UserSignInCompleteDialog } from "components/user";
import styles from "./AuthPage.module.css";
import { AuthNextSignInStep } from "@aws-amplify/auth/dist/esm/types";

const SignInPage: FunctionComponent = () => {

  // The cognito user response is needed to complete sign-in if this is the user's first sign-in ever.
  const [cognitoUser, setCognitoUser] = useState<AuthNextSignInStep | null>(null);

  return (
    <PageLayout header footer className={styles.page}>
      {
        cognitoUser
        ? <UserSignInCompleteDialog showTitle />
        : <UserSignInDialog showTitle onChangeCognitoUser={setCognitoUser} />
      }
    </PageLayout>
  );
};

export default SignInPage;
