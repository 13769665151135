import { fetchAuthSession } from "@aws-amplify/auth";
import CartaObjectStorage from "data/CartaObjectStorage"
import { config } from "library/api"
import { useState } from "react";


export default function useCartaObjectStorage() {

    const [cartaObjectStorage] = useState<Promise<CartaObjectStorage>>((async ()=>{

        const host = (await config).host;
        const isDev = (await config).isDev;
        const idToken = (await fetchAuthSession()).tokens?.idToken?.toString();

        if(!idToken){
            throw new Error("Unable to get auth token for Carta Object Storage")
        }
        
        return new CartaObjectStorage(host, idToken, !isDev);
    })());

    return cartaObjectStorage;
}