import { CognitoPasswordPolicy } from "./CognitoTypes";

const config = import("../../deploy.config.json") as Promise<{
  host: string;
  isDev: boolean;
  userPool: {userPoolId: string, region: string, userPoolWebClientId: string};
  passwordPolicy: CognitoPasswordPolicy;
}>;

export default config;
