import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { User, config } from "library/api";
import { LogSeverity } from "library/logging";
import { UserContext } from "components/user";
import { useNotifications } from "components/notifications";
import { LocalCognitoCodeDelivery } from "./CognitoTypes";
import { cognitoUserPoolsTokenProvider, confirmResetPassword, confirmSignIn, fetchUserAttributes, getCurrentUser, resetPassword, signIn, signOut } from '@aws-amplify/auth/cognito';
import { Amplify, ResourcesConfig } from "aws-amplify";
import { CookieStorage } from 'aws-amplify/utils';
import { fetchAuthSession } from "@aws-amplify/auth";

async function configureCognito(){
  const {userPool} = (await config)
  const host = document.location.hostname;
  let domain = undefined;
  if(host.indexOf("localhost") === -1){
    if(host.indexOf("-dev") === -1){
      domain = ".contextualize.us.com"
    }else{
      domain = ".contextualize-dev.com"
    }
  }
  const authConfig: ResourcesConfig['Auth'] = {
    Cognito: {...userPool, userPoolClientId: userPool.userPoolWebClientId},

    };
  Amplify.configure({Auth: authConfig});
  cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({ domain }))
}

const cognitoConfigured = configureCognito();

/** A component that wraps a user context around its children components. */
const UserWrapper: FunctionComponent = ({ children }) => {
  // We use this state to store information about whether the user is authenticated and their relevant information.
  const [user, setUser] = useState<User | null>(null);

  // This method updates the user state.
  const { logger } = useNotifications();
  const updateAuthUser = useCallback(
    async (logErrors?: boolean) => {
      try {
        await cognitoConfigured;
        const _user = await getCurrentUser();
        const userAttr = await fetchUserAttributes();
        setUser({
          name: _user.username,
          firstName: userAttr.given_name,
          lastName: userAttr.family_name,
          email: userAttr.email ?? "Unknown Email",
          id: _user.userId,
          groups: [],
        });
      } catch (error: any) {
        if (logErrors) {
          logger.log({
            source: "User Wrapper",
            title: "Failed to fetch user information",
            severity: LogSeverity.Error,
            message: error.message,
          });
        } else setUser(null);
      }
    }, [logger]);

  useEffect(()=>{
    (async () => {
      await cognitoConfigured;
      await updateAuthUser();
    })();
  }, [updateAuthUser])


  // Prepare functions to handle authentication related requests.
  const handleSignIn = async (username: string, password: string) => {
    // Execute sign in request.
    const data = await signIn({username, password});
    if (data.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
      return data.nextStep;
    }
    const user = await getCurrentUser();
    const userAttr = await fetchUserAttributes();

    setUser({
      name: user.username,
      firstName: userAttr.given_name,
      lastName: userAttr.family_name,
      email: userAttr.email ?? "unknown email",
      id: user.userId,
      groups: [],
    });
    return null;
  };

  const handleCompleteSignIn = async (
    password: string,
    firstname: string,
    lastname: string
  ) => {
    // Execute complete sign in request.
    
    await confirmSignIn({
        challengeResponse: password,
        options: {
          userAttributes: {
            given_name: firstname,
            family_name: lastname
          }
        }
    });
    
    await updateAuthUser();
  };
  const handleForgotPassword = async (username: string) => {
    const data = await resetPassword({username});
    return {
      attributeName: data.nextStep.codeDeliveryDetails.attributeName,
      deliveryMedium: data.nextStep.codeDeliveryDetails.deliveryMedium,
      destination: data.nextStep.codeDeliveryDetails.destination,
    } as LocalCognitoCodeDelivery;
  };
  const handleResetPassword = async (
    username: string,
    code: string,
    password: string
  ) => {
    setUser(null);
    return await confirmResetPassword({username, confirmationCode: code, newPassword: password});
  };
  const handleSignOut = async (apiless?: boolean) => {
    // Execute sign out request.
    try {
      if (!apiless) await signOut();
      setUser(null);
    } catch (error: any) {
      logger.log({
        source: "User Wrapper",
        title: "Failed to sign out",
        severity: LogSeverity.Error,
        message: error.message,
      });
    }
  };

  // Setup a timer to check when the tokens expire.
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const checkSession = async () => {
      await cognitoConfigured;
      try {
        if (!user) return;
        const session = await fetchAuthSession();
        
        if (typeof session.tokens?.idToken === "undefined") {
          setUser(null);
        }

        /** experiation in milliseconds */
        const expiration = (session.tokens?.idToken?.payload.exp ?? 0) * 1000;
        timeout = setTimeout(
          checkSession,
          expiration - Date.now()
        );
      } catch (error: any) {
        logger.log({
          source: "User Wrapper",
          title: "Failed to check session",
          severity: LogSeverity.Error,
          message: error.message,
        });
      }
    };
    timeout = setTimeout(checkSession, 0);
    return () => clearTimeout(timeout);
  }, [logger, user]);

  return (
    <UserContext.Provider
      value={{
        user: user,
        authenticated: user !== null,
        signIn: handleSignIn,
        signOut: handleSignOut,
        completeSignIn: handleCompleteSignIn,
        forgotPassword: handleForgotPassword,
        resetPassword: handleResetPassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserWrapper;
