import { JSONSchema7 } from "json-schema";

export type ContextualizeJSONSchema7Definition = ContextualizeJSONSchema7 | boolean;

export interface ContextualizeJSONSchema7 extends JSONSchema7{
    propertyOrder?: number;
    properties?:  {[key: string]: ContextualizeJSONSchema7Definition}
    dependencies?: {[key: string]: ContextualizeJSONSchema7Definition | string[]}
    oneOf?: ContextualizeJSONSchema7Definition[]
}

export function normalizeDefinitions(schema: JSONSchema7, definitions?: {[key: string]: JSONSchema7}){
    if(!definitions){
      definitions = schema.definitions as {[key: string]: JSONSchema7};
    }
    let value: JSONSchema7 | false = false;
    if(schema.$ref){
      const path = schema.$ref.split('/');
      if(path.length > 3){
        throw new Error("Deep reference not supported");
      }
      const refValue = definitions[path[2]]
      value = {...schema, ...refValue};
      delete value.$ref;
    }
  
    if(schema.properties){
      Object.entries(schema.properties).forEach(([key, property])=>{
        if(typeof property === 'boolean')
          return;
  
        const changed = normalizeDefinitions(property, definitions);
        if(changed){
          (schema.properties as {[key: string]: JSONSchema7})[key] = changed;
        }
      })
    }
  
    if(schema.items){
      if(Array.isArray(schema.items)){
        schema.items.forEach((item, index)=>{
          if(typeof item === 'boolean')
            return;
  
          const changed = normalizeDefinitions(item, definitions);
          if(changed){
            (schema.items as JSONSchema7[])[index] = changed;
          }
        })
      }else if(typeof schema.items !== 'boolean'){
        const changed = normalizeDefinitions(schema.items, definitions);
        if(changed){
          schema.items = changed;
        }
      }
    }
  
    return value;
  }

  export function flattenDependencies(property: ContextualizeJSONSchema7){

    if(property.dependencies){
        // Create a deep clone of the properties
        let properties = JSON.parse(JSON.stringify(property.properties ?? {})) as {[key: string]: ContextualizeJSONSchema7Definition}

        // dependancies will have a key of a property
        Object.entries(property.dependencies).forEach(([key, conditional])=>{
          if(Array.isArray(conditional)){
            // IDK what it means if the conditional is an array
            return;
          }
          if(typeof conditional === 'boolean'){
            // IDK what it means if the conditional is a boolean
            return;
          }
          
          // The conditional must be a JSONSchema Type with an entry for 'oneOf'
          // oneOf is an array of JSONSchema where properties are defined
          // these properties are to be merged with the base properties
          if(conditional.oneOf){
            conditional.oneOf.forEach((condition)=>{
              if(typeof condition === 'boolean'){
                return;
              }
              if(!condition.properties){
                return;
              }

              Object.entries(condition.properties).forEach(([cKey, cProperty])=>{
                
                if(typeof cProperty === 'boolean'){
                    // ??? Ignore it for now
                    return;
                }

                // Create a blank property if it doesn't exist
                if(!(cKey in properties)){
                    properties[cKey] = {}
                }

                let prop = properties[cKey];
                
                if((typeof prop === 'boolean')){
                    properties[cKey] = {...cProperty};
                    return;
                }

                prop.type = prop.type ?? cProperty.type
                prop.default = prop.default ?? cProperty.default
                prop.title = prop.title ?? cProperty.title
                prop.default = prop.propertyOrder ?? cProperty.propertyOrder

                if(cProperty.enum){
                    prop.enum = cProperty.enum.concat(prop.enum ?? []).filter((value, index, arr)=>{
                        return (arr.indexOf(value) === index)
                    })
                }
              })
            })
          }
        })
        property.properties = properties;
      }
  }
  