import { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import {
  getInputProps,
  labelValue,
  FieldProps,
} from '@rjsf/utils';


/** The `BaseInputTemplate` is the template to use to render the basic `<input>` component for the `core` theme.
 * It is used as the template for rendering many of the <input> based widgets that differ by `type` and callbacks only.
 * It can be customized/overridden for other themes or individual implementations as needed.
 *
 * @param props - The `WidgetProps` for this template
 */
export default function BaseInputTemplate(props: FieldProps) {
  const {
    id,
    placeholder,
    required,
    readonly,
    disabled,
    type,
    hideLabel,
    value,
    onChange,
    autofocus,
    options,
    schema,
    rawErrors = [],
    InputLabelProps
  } = props;
  const inputProps = getInputProps(schema, type, options);
  // Now we need to pull out the step, min, max into an inner `inputProps` for material-ui
  const { step, min, max, ...rest } = inputProps;
  const label = props.schema.title;
  const otherProps = {
    inputProps: {
      step, 
      min,
      max
    },
    ...rest,
  };
  const _onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onChange(value === '' ? options.emptyValue : parseFloat(value));
  const DisplayInputLabelProps = InputLabelProps;
  return (
      <TextField
        id={id}
        name={id}
        placeholder={placeholder}
        label={labelValue(label || undefined, hideLabel, undefined)}
        autoFocus={autofocus}
        required={required}
        disabled={disabled || readonly}
        {...otherProps}
        defaultValue={props.formData}
        value={value}
        error={rawErrors.length > 0}
        onChange={_onChange}
        InputLabelProps={DisplayInputLabelProps}
        onWheel={(event)=>(event.target as HTMLInputElement).blur()}
      />
  );
}
