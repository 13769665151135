import { FunctionComponent } from "react";
import { JSONSchema7Definition } from 'json-schema'
import { TextField } from "@mui/material";
import { PrimaryKeyField } from "./PrimaryKeyField";
import { ForeignKeyField } from "./ForeignKeyField";
import { SelectField } from "./SelectField";
import { minTableColumnWidth } from "./const";
import { ArrayField } from "./ArrayField";

interface CartaSchemaProperty{
    "&pk"?: boolean;
    "&fk"?: boolean;
}

export interface TableFormFieldProps {
    property: JSONSchema7Definition & CartaSchemaProperty;
    defaultValue: any;
    onChange: (value: any) => void
}

export const TableFormField: FunctionComponent<TableFormFieldProps> = ({
    property,
    defaultValue,
    onChange
}) => {

    if (typeof property === 'boolean') {
        return (<></>)
    }

    if(property["&pk"]){
        return (
                <PrimaryKeyField defaultValue={defaultValue} required={true} onChange={onChange}/>
        )
    }

    if(property["&fk"]){
        return (
                <ForeignKeyField defaultValue={defaultValue} required={true} onChange={onChange}/>
        )
    }

    if(property.enum){
        return (
            <SelectField 
            label={property.title??""}
            defaultValue={defaultValue} 
            required={false} 
            options={property.enum.map(e=>e?.toString() ?? "")} 
            onChange={onChange}/>
        )
    }

    if(property.type){
        switch(property.type){
            case "number":
                return (
                        <TextField
                            defaultValue={defaultValue ?? ""}
                            onChange={(e) => onChange(parseFloat(e.target.value))}
                            fullWidth
                            
                            style={{minWidth: minTableColumnWidth}}
                        />
                )
            case "array":
                return (
                    <ArrayField schema={property} defaultValue={defaultValue} onChange={onChange} required={false}/>
                )
        }
    }

    return (
            <TextField
                defaultValue={defaultValue ?? ""}
                onChange={(e) => onChange(e.target.value)}
                fullWidth
                style={{minWidth: minTableColumnWidth}}
            />
    )
}